@use '../../global.scss' as *;

.layout {
  background: #000;
  min-height: 100vh;
  color: #fff;
}

header.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: toRem(16) toRem(70);
  background: #ffffff;

  ul {
    background-color: white;
    align-items: center;
  }

  .logo {
    display: flex;

    img {
      height: toRem(36);
      width: auto;
    }
  }
}

// button.blackBtn,
// .ant-btn-primary {
//   color: white;
//   background-color: black;
//   font-weight: $font-regular;
//   font-size: toRem(13);
//   height: initial;
//   min-height: toRem(30);
//   padding: toRem(9) toRem(18);

//   &:hover,
//   &:not(:disabled):not(.ant-btn-disabled):hover {
//     color: $base-heading;
//     background-color: $base-bg;
//     border-color: $base-heading;
//   }
// }

a {
  font-size: toRem(13);
}

.menu {
  flex: 1;
  justify-content: flex-end;
  background: transparent;
  border-bottom: none;
}

/* Hide Mobile Menu Button on Desktop */
.mobileMenu {
  display: none;
}

/* Drawer Menu Styling */
.drawer .ant-drawer-body {
  padding: 0;
}

/* Second Section - What is Imprint? */
.secondSection {
  text-align: center;
  background: #fff;
  padding: toRem(50) toRem(70);

  @include media(md, lg, xl) {
    padding-top: toRem(130);
  }

  h2 {
    font-size: toRem(45);
    font-weight: 800;
    margin-bottom: toRem(30);
  }

  .card {
    background: #fff;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: toRem(8);

    .descipVideo {
      padding: toRem(16);

      h3 {
        font-size: toRem(25);
        font-weight: 800;
        color: #393c4c;
        line-height: toRem(40);
      }

      p {
        font-size: toRem(16);
        color: #626883;
        font-weight: 500;
      }
    }
  }
}

.videoWrapper {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  
  &:hover {
    .playButton {
      background-color: #ff0000;
      
      &::before {
        border-color: transparent transparent transparent #ffffff;
      }
    }
    
    .videoThumbnail {
      transform: scale(1.05);
    }
  }
}

.videoThumbnail {
  width: 100%;
  transition: transform 0.3s ease;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  
  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #ff0000;
    margin-left: 4px;
  }

  &:hover {
    background-color: #ff0000;
    
    &::before {
      border-color: transparent transparent transparent #ffffff;
    }
  }
}

.membershipSection {
  text-align: center;
  background: #f9f9f9;
  padding: toRem(50) toRem(70);

  .membershipHeading {
    font-size: toRem(48);
    font-weight: 800;
    margin-bottom: toRem(16);
    line-height: toRem(50);
    color: black;
  }

  .membershipParagraph {
    font-size: toRem(18);
    color: #626883;
    margin-bottom: toRem(30);
  }
}

/* Plan Cards */
.planCard {
  background: #fff;
  border-radius: toRem(10);
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Join The Movement Button */
.centerButton {
  text-align: center;
  margin-top: toRem(30);
  justify-content: center;
  display: flex;
}

.movementButton {
  background: linear-gradient(to right, #f5a623, #f76b1c);
  padding: toRem(12) toRem(30);
  font-size: toRem(18);
  border: none;
}

.contentMain {
  text-align: center;
  padding: 4rem 2rem;
  background-color: black;
  height: 80vh;
}

.textSection {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: toRem(30);

  h1 {
    font-size: toRem(48);
    color: white;
    line-height: toRem(56);
    margin-bottom: toRem(16);
    font-weight: 900;
  }

  p {
    font-size: toRem(18);
    color: white;
    margin-bottom: toRem(16);
    line-height: toRem(30);
  }
}

.imageRow {
  img.heroImage {
    background: transparent;
    max-width: 100%;
      height: auto;
  }
}

.cardRow {
  align-items: flex-end;

  :global(.plan-card-container) {
    background-color: white;
    border: 1px solid #D2D5DB;
    border-radius: toRem(8);
  }
}

.videoModal {
  :global(.ant-modal-content) {
    background-color: #000000;
    border-radius: 12px;
    overflow: hidden;
    padding: 0;
    
    .ant-modal-close {
      top: 16px;
      right: 16px;
      color: #ffffff;
      z-index: 1000;
      transition: all 0.3s ease;
      
      &:hover {
        transform: scale(1.1);
        color: #f0f0f0;
      }
      
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
    }

    .ant-modal-body {
      padding: 0;
    }
  }
}

.videoContainer {
  background: #000000;
  
  .videoWrapper {
    position: relative;
    padding-top: 56.25%; // 16:9 aspect ratio
    width: 100%;
    background: #000000;
    
    .videoFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  
  .videoInfo {
    padding: 20px 24px;
    background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.8));
    
    h3 {
      color: #ffffff;
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: 500;
    }
    
    p {
      color: #cccccc;
      font-size: 14px;
      margin: 0;
    }
  }
}

@media (max-width: $md) {
  .menu {
    display: none;
  }

  .mobileMenu {
    display: block;
    background: none;
    border: none;
    font-size: toRem(21);
    color: white;
  }

  .contentMain {
    text-align: center;
    padding: toRem(20);
    height: 100%;
  }

  .heroRow {
    margin-bottom: toRem(32);
  }

  .textSection {
    h1 {
      font-size: toRem(37);
      margin-bottom: toRem(16);
    }

    p {
      font-size: toRem(18);
      margin-bottom: toRem(32);
    }
  }

  .heroImage {
    width: 100%;
    border-radius: 10px;
  }

  .secondSection {
    padding: toRem(20);

    h2 {
      font-size: toRem(22);
    }
  }

  .membershipSection {
    padding: toRem(20);

    h2 {
      font-size: toRem(25);
      line-height: toRem(35);
    }

    p {
      font-size: toRem(16);
    }
  }


  .planCard {
    height: 220px !important;
  }
}

@media (max-width: 768px) {
  .videoModal {
    :global(.ant-modal-content) {
      margin: 10px;
      
      .ant-modal-close {
        top: 8px;
        right: 8px;
      }
    }
  }
  
  .videoInfo {
    h3 {
      font-size: 18px;
    }
    
    p {
      font-size: 13px;
    }
  }
  
  .playButton {
    width: 48px;
    height: 48px;
    font-size: 20px;
  }
}