//Responsinve Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1600px;

// Color Codes
$base-text: #6c727f;
$base-heading: #101418;
$base-bg: #f4f4f4;
$base-success: #72c040;
$base-error: #ec5b56;
$base-warning: #efaf41;
$base-info: #3479f7;
$base-white: #ffffff;
$base-border-color: #e7e7e7;
$placeholders: #999eb3;
$tab-text: #393c4c;
$button-secondary: #ebebeb;
$scrollbar-thumb: #393c4c;
$scrollbar-bg: #e7e7e7;