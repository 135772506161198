@use '../../../../global.scss' as *;

.plan-card-container {
  border-radius: toRem(20);
  padding: toRem(36) toRem(24);
  display: flex;
  flex-direction: column;
  gap: toRem(16);
  top: toRem(8);
  overflow: hidden;
  position: relative;

  .plan-visual-card {
    border-bottom: toRem(30) solid black;
    border-radius: 0 0 toRem(6) toRem(6);
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
      min-width: 100%;
      height: auto;
      object-fit: fill;
    }
  }

  .card-heading {
    h2 {
      font-size: toRem(24);
      font-weight: $font-bold;
      line-height: toRem(24);
      margin: 0;
    }

    p {
      font-size: toRem(12);
      font-weight: $font-medium;
      line-height: toRem(18);
      color: $base-text;
      margin: 0;

      strong {
        font-size: toRem(20);
        font-weight: $font-semibold;
        line-height: toRem(24);
        color: $base-heading;
      }
    }
  }

  .card-description {
    font-size: toRem(14);
    font-weight: $font-regular;
    line-height: toRem(18.2);
    // height: toRem(30);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: start;
  }

  .card-details {
    // margin-top: auto;
    text-align: center;

    .ant-col {
      display: flex;
      flex-direction: column;
      gap: toRem(8);

      p {
        font-size: toRem(12);
        font-weight: $font-semibold;
        line-height: toRem(12);
      }

      strong {
        font-size: toRem(20);
        font-weight: $font-bold;
        background: linear-gradient(90deg, #fcba06 0%, #e99e02 54%, #dc8b00 100%);
        -webkit-background-clip: text;
        color: transparent;
      }
    }
  }

  .ant-divider {
    margin: toRem(8);
  }

  .footer-text {
    font-size: toRem(16);
    font-weight: $font-regular;
    line-height: toRem(22);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: toRem(8);
  }

  .basic-plan-button {
    &:hover {
      background-color: #333333 !important;
      border-color: #333333 !important;
    }
    
    &:active {
      background-color: #000000 !important;
      border-color: #000000 !important;
    }
  }
}

.divider {
  border-right: 1px solid $placeholders;
}

.subscription-code-modal {
  :global(.ant-modal-content) {
    .ant-modal-header {
      text-align: center;

      .ant-modal-title {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .ant-modal-body {
      padding: 24px;
      text-align: center;

      .subscription-input {
        max-width: 200px;
        margin: 0 auto;
        transition: max-width 0.3s ease;
        text-align: center;

        &:focus {
          max-width: 100%;
          text-align: left;
        }
      }
    }

    .ant-modal-footer {
      text-align: center;

      button {
        margin: 0 8px;
      }
    }
  }
}

.subscription-code-modal .ant-modal-footer {
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.subscription-code-modal .ant-modal-content {
  min-width: 100px !important;
  background: white !important;
  border: 10px solid #f4f4f4 !important;
}