@import "./styles/_functions";
@import "./styles/_variables";
@import "./styles/_mixins";
@import "./styles/_typography";

* {
  font-family: "Poppins", sans-serif;
  @include scrollbars();
}

body {
  margin: 0;
}