@use '../../../global.scss' as *;

.layout {
  background: #000;
  min-height: 100vh;
  color: $base-bg;
  overflow-x: hidden;
}

header.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: toRem(16) toRem(70);
  background: #ffffff;

  ul {
    background-color: white;
    align-items: center;
  }

  .logo {
    display: flex;

    img {
      height: toRem(36);
      width: auto;
    }
  }
}

.mobileMenu {
  display: none;
}

.drawer .ant-drawer-body {
  padding: 0;
}

button.blackBtn,
.ant-btn-primary {
  color: white;
  background-color: black;
  font-weight: $font-regular;
  font-size: toRem(13);
  height: initial;
  min-height: toRem(30);
  padding: toRem(9) toRem(18);

  &:hover,
  &:not(:disabled):not(.ant-btn-disabled):hover {
    // color: black !important;
    color: $base-heading;
    background-color: $base-bg;
    border-color: $base-heading;
  }
}

.menu {
  flex: 1;
  justify-content: flex-end;
  // background: transparent;
  border-bottom: none;
  background-color: purple;
  &:hover,
  &:focus,
  &:active {
    :global(.ant-menu > .ant-menu-item-selected::after) {
      border-bottom-color: $base-heading;
    }
  }
}
