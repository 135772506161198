@import "./variables";

@mixin sm {
    @media only screen and (min-width: $sm) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: $md) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: $lg) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: $xl) {
        @content;
    }
}

@mixin xxl {
    @media only screen and (min-width: $xxl) {
        @content;
    }
}

@mixin xxxl {
    @media only screen and (min-width: $xxxl) {
        @content;
    }
}

@mixin media($keys...) {
    @each $key in $keys {
        @if ($key==xs) {
            @include xs {
                @content;
            }
        }

        @else if ($key==sm) {
            @include sm {
                @content;
            }
        }

        @else if ($key==md) {
            @include md {
                @content;
            }
        }

        @else if ($key==lg) {
            @include lg {
                @content;
            }
        }

        @else if ($key==xl) {
            @include xl {
                @content;
            }
        }

        @else if ($key==xxl) {
            @include xxl {
                @content;
            }
        }

        @else if ($key==xxl) {
            @include xxl {
                @content;
            }
        }
    }
}

@mixin scrollbars() {

    // For Google Chrome
    &::-webkit-scrollbar {
        width: toRem(8);
        height: toRem(8);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #393c4c;
        -webkit-box-shadow: none;
        border-radius: toRem(4);
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        background-color: $scrollbar-bg;
        border-radius: toRem(4);
    }

    // For Internet Explorer
    & {
        scrollbar-face-color: #393c4c;
        scrollbar-track-color: $scrollbar-bg;
    }

    // For Safari
    &::-webkit-scrollbar {
        width: toRem(8);
        height: toRem(8);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #393c4c;
        border-radius: toRem(4);
    }

    &::-webkit-scrollbar-track {
        background-color: $scrollbar-bg;
        border-radius: toRem(4);
    }
}