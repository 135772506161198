@use "../../../global.scss" as *;

button {
  &:disabled {
    cursor: default !important;
  }
}

button.gold-button,
.ant-btn,
.ant-btn-primary {
  background: linear-gradient(to right, #f5a623, #f76b1c);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: initial;
  min-height: toRem(30);
  padding: toRem(9) toRem(18);
  font-size: toRem(13);
  color: white;

  &:hover,
  &:not(:disabled):not(.ant-btn-disabled):hover {
    background: linear-gradient(to right, #f76b1c, #f5a623) !important;
    transform: scale(1.05);
    color: white;
  }
}

button.black-button,
.ant-btn-primary {
  color: white;
  background-color: black;
  font-weight: $font-regular;
  font-size: toRem(13);
  height: initial;
  min-height: toRem(30);
  padding: toRem(9) toRem(18);

  &:hover,
  &:not(:disabled):not(.ant-btn-disabled):hover {
    color: $base-heading;
    background-color: $base-bg;
    border-color: $base-heading;
  }
}