@use '../../../global.scss' as *;

footer {
  text-align: center;
  padding: toRem(16) 0 toRem(16);

  ul {
    li {
      display: inline-block;
    }

    &.footer-social {
      li {
        margin: 0 toRem(8);

        a {
          background-color: darken($base-bg, 10%);
          padding: toRem(13);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: toRem(44);
          height: toRem(44);

          &:hover,
          &:focus,
          &:active {
            background-color: darken($base-bg, 15%);
          }
        }
      }
    }

    &.footer-nav {
      margin: toRem(16) 0 toRem(4);

      li {
        margin: 0 toRem(12) toRem(12);

        a {
          color: lighten($base-text, 20%);
          font-size: toRem(13);
          font-weight: $font-medium;

          &:hover,
          &:focus,
          &:active {
            text-decoration: underline;
          }
        }
      }
    }
  }

  p {
    color: lighten($base-text, 20%);
    font-size: toRem(13);
    font-weight: $font-medium;
  }
}
