@use "../../global.scss" as *;

.storyContainer {
  text-align: center;
  background: #f4f4f4;
  padding: toRem(50) toRem(70);
  
  .storyRow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textSection {
    h2 {
      font-size: toRem(45);
      font-weight: 800;
      margin-bottom: toRem(30);
    }

    p {
      font-size: toRem(16);
      color: #626883;
      font-weight: 500;
      line-height: toRem(24);
      margin-bottom: toRem(16);
    }
  }

  .cardContent {
    background: #ffffff !important;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    border-radius: toRem(8);
    padding: toRem(16);
  }

  .imageSection {
    text-align: center;
  }

  .storyImage {
    max-width: 100%;
    height: auto;
    border-radius: toRem(8);
  }
}

.aboutContainer {
  background: #111; // Dark background to match the design
  color: #fff;
  padding: toRem(50) toRem(70);

  @include media(lg, xl) {
    padding-bottom: 0;

    .imageSection {
      img {
        margin-bottom: toRem(-10);
      }
    }
  }

  .aboutRow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .textSection {
    h2 {
      font-size: toRem(45);
      font-weight: 800;
      margin-bottom: toRem(30);
      color: #fff;
    }

    p {
      font-size: toRem(16);
      color: #d1d1d1;
      font-weight: 500;
      line-height: toRem(24);
    }

    strong {
      font-weight: 700;
      color: #fff;
    }
  }

  .cardContentBlack {
    background: transparent;
    border: none;
    text-align: left;
    padding: toRem(16);
  }

  .imageSection {
    text-align: center;
  }

  .aboutImage {
    max-width: 100%;
    height: auto;
    border-radius: toRem(8);
  }
}

.foundersContainer {
  background: #f8f9fa;
  padding: toRem(50) toRem(70);
  border-radius: toRem(10);

  .foundersRow {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .textSection {
    h2 {
      font-size: toRem(45);
      font-weight: 800;
      margin-bottom: toRem(20);
      color: #222;
    }

    .highlight {
      font-size: toRem(22);
      font-weight: 700;
      color: #f59e0b;
    }

    .subText {
      font-size: toRem(16);
      font-weight: 500;
      color: #626883;
      margin-bottom: toRem(15);
    }

    p {
      font-size: toRem(16);
      color: #555;
      font-weight: 500;
      line-height: toRem(24);
    }
  }

  .cardContent {
    background: #fff;
    border-radius: toRem(8);
    padding: toRem(20);
    box-shadow: none !important;
    text-align: left;
  }

  .imageSection {
    text-align: center;
  }

  .foundersImage {
    max-width: 100%;
    height: auto;
    border-radius: toRem(8);
  }
}

.buddyhoodContainer {
  text-align: center;
  background: #f8f9fa;
  padding: toRem(50) toRem(70);
  border-radius: toRem(10);

  .title {
    font-size: toRem(40);
    font-weight: 800;
    color: #222;
    margin-bottom: toRem(10);
  }

  .subtitle {
    font-size: toRem(18);
    color: #666;
    margin-bottom: toRem(30);
  }

  .cardsRow {
    display: flex;
    justify-content: center;
    gap: toRem(16);
    margin-bottom: toRem(30);
  }

  .infoCard {
    text-align: center;
    background: #fff;
    border-radius: toRem(10);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    .blackbox {
      background-color: black;
      padding: toRem(16);

      h3 {
        font-size: toRem(18);
        font-weight: 700;
        color: white;
      }
    }

    .imgbox {
      padding: toRem(30);
    }
  }

  .icon {
    max-width: 100%;
    height: auto;
    border-radius: toRem(8);
  }

  .description {
    font-size: toRem(16);
    color: #555;
    margin-bottom: toRem(20);
  }

  .btnGold {
    margin: 0 auto;
  }
}

@media (max-width: $md) {
  .menu {
    display: none;
    /* Hide menu on mobile */
  }

  .mobileMenu {
    display: block;
    /* Show menu button on mobile */
    background: none;
    border: none;
    font-size: toRem(21);
    color: white;
  }

  .storyContainer {
    padding: toRem(20);
  }

  .aboutContainer {
    padding: toRem(20);

    .imageSection {
      order: 2;

      img {
        margin-bottom: toRem(-30);
      }
    }
  }

  .foundersContainer {
    padding: toRem(20) toRem(32);

    .cardContent {
      :global(.ant-card-body) {
        padding: 0;
      }
    }
  }

  .buddyhoodContainer {
    padding: toRem(20);
  }
}
